

@font-face {
    font-family: 'PoppinsMedium';
    src: url('../fonts/Poppins/Poppins-Medium.eot');
    src: url('../fonts/Poppins/Poppins-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins/Poppins-Medium.woff2') format('woff2'),
        url('../fonts/Poppins/Poppins-Medium.woff') format('woff'),
        url('../fonts/Poppins/Poppins-Medium.ttf') format('truetype'),
        url('../fonts/Poppins/Poppins-Medium.svg#Poppins-Medium') format('svg');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'PoppinsRegular';
    src: url('../fonts/Poppins/Poppins-Regular.eot');
    src: url('../fonts/Poppins/Poppins-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins/Poppins-Regular.woff2') format('woff2'),
        url('../fonts/Poppins/Poppins-Regular.woff') format('woff'),
        url('../fonts/Poppins/Poppins-Regular.ttf') format('truetype'),
        url('../fonts/Poppins/Poppins-Regular.svg#Poppins-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'PoppinsBold';
    src: url('../fonts/Poppins/Poppins-Bold.eot');
    src: url('../fonts/Poppins/Poppins-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins/Poppins-Bold.woff2') format('woff2'),
        url('../fonts/Poppins/Poppins-Bold.woff') format('woff'),
        url('../fonts/Poppins/Poppins-Bold.ttf') format('truetype'),
        url('../fonts/Poppins/Poppins-Bold.svg#Poppins-Bold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'PoppinsSemiBold';
    src: url('../fonts/Poppins/Poppins-SemiBold.eot');
    src: url('../fonts/Poppins/Poppins-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins/Poppins-SemiBold.woff2') format('woff2'),
        url('../fonts/Poppins/Poppins-SemiBold.woff') format('woff'),
        url('../fonts/Poppins/Poppins-SemiBold.ttf') format('truetype'),
        url('../fonts/Poppins/Poppins-SemiBold.svg#Poppins-SemiBold') format('svg');
    font-weight: normal;
    font-style: normal;
}