.heading-1,.heading-2, .heading-3, .paragraph, .card-title,.paragraph-to-caption, .caption, .labeling  {

    font-family: var(--font-primary);
    color: var(--brand-primary);
    &.regular {
        font-family: var(--font-secondary);
    }
    &.white {
        color:var(--brand-white);
    }
    &.text-link {
        color: var(--blue-link);
    }

    &.upper {
        text-transform: uppercase;
    }
}

//3/4 = 0.75 2/4 = 0.5 1/4 

.heading-1 {
    
    line-height: 1.4;
    font-size: 1.9rem;

    @media (min-width: 768px) {
        font-size: 2rem;
    }

    @media (min-width: 992px) {
        font-size: 2.475rem;
    }

    @media (min-width: 1200px) {

        font-size: 2.92rem;

    }
}

.heading-2 {
    
    line-height: 1.5;
    font-size: 1.7rem;

    @media (min-width: 768px) {
        font-size: 1.8rem;
    }

    @media (min-width: 992px) {
        font-size: 2rem;
    }

    @media (min-width: 1200px) {

        font-size: 2.21rem;

    }
}

.heading-3 {
    line-height: 1.4;
    font-size: 1.2rem;

    @media (min-width: 768px) {
        font-size: 1.3rem;
    }

    @media (min-width: 992px) {
        font-size: 1.475rem;
    }

    @media (min-width: 1200px) {

        font-size: 1.71rem;
    }
}

.card-title {
    
    line-height: 1.3;
    font-size: 1.1rem;

    @media (min-width: 768px) {
        font-size: 1.22rem;
    }

    @media (min-width: 992px) {
        font-size: 1.32rem;
    }

    @media (min-width: 1200px) {

        font-size: 1.42rem;
    }
}


.paragraph {

    font-size: 1rem;
    line-height: 1.6;
    @media (min-width: 992px) {
        font-size: 1.07rem;
    }

    @media (min-width: 1200px) {
        font-size: 1.14rem;
    }
}

.paragraph-to-caption {

    font-size: 0.85rem;
    line-height: 1.4;

    @media (min-width: 420px) {
        font-size: 1.07rem;
    }

    @media (min-width: 768px) {
        font-size: 1.07rem;
    }

    @media (min-width: 992px) {
        font-size: 1.07rem;
    }

    @media (min-width: 1200px) {
        font-size: 1.14rem;
    }
}

.caption {
    font-size: 0.85rem;
    line-height: 1.4;
}

.labeling {
    font-size: 1rem;
    @media only screen and (max-width:420px) {
        font-size: 0.85rem;
    }
}